import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Room,
  WhatsApp,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  color: white;
  background-color: teal;
  display: flex;
  padding-bottom: 40px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    //padding:25px;
    padding: 0;
    text-align: center;
  }
`;
const Logo = styled.h1`
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 5px;
  font-family: "Righteous";
  padding: 20px 20px 10px 20px;
  margin: 0px;
  cursor: pointer;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;
const Desc = styled.p`
  text-align: left;
  max-width: 80vw;
`;
const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;
const SocialIcon = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #3b5999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #6b76da;
  }
`;

const Center = styled.div`
  flex: 1;
`;
const Title = styled.h3`
  margin-bottom: 30px;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-family: "Righteous";
  margin: 30px 0px;
`;

const List = styled.div`
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ListItem = styled.h2`
  margin: 10px;
  font-size: 12px;
  font-weight: 700;
  //line-height: 16px;
  text-transform: uppercase;
`;
const LinkListItem = styled.a`
  transition: opacity 0.15s;
  cursor: pointer;
  border-bottom: 2px solid #3b5999;
  &:hover {
    color: #dddddd;
  }
`;
const Right = styled.div`
  flex: 1;
  margin: 0;
  padding: 0;
`;
const RightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
const ContactWrapper = styled.div`
  border: 1px solid white;
  padding: 0;
  margin: 0;
  width: 32.333%;
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.5);
  margin: 2px 0px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  @media only screen and (max-width: 768px) {
    width: 49%;

    height: 70px;
  }
`;
const ContactHeader = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Righteous";
`;
const ContactText = styled.h3`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  text-align: center;
`;
const ContactItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;
const Icon = styled.div`
  //padding:5px;
  display: inline;
  font-size: 10px;
`;
const Footer = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Left>
        <Logo>{t("Footer.SNUS_SRBIJA")}</Logo>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontSize: "50px",
              fontWeight: "700",
              margin: "0px",
              padding: "0px",
            }}
          >
            18+
          </p>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "700",
              margin: "0px",
              padding: "0px",
              fontFamily: "Righteous",
            }}
          >
            {t("Footer.AgeRestriction")}
          </p>
        </div>
        <Desc>{t("Footer.Description")}</Desc>
        <SocialContainer>
          <SocialIcon
            href="https://www.facebook.com/snussrbijahub/"
            aria-label="Posetite našu Facebook stranicu"
          >
            <Facebook />
          </SocialIcon>
          <SocialIcon
            href="https://www.instagram.com/"
            aria-label="Posetite našu Instagram stranicu"
          >
            <Instagram />
          </SocialIcon>
          <SocialIcon
            href="https://wa.me/message/LTLCI6ROYGLQD1"
            aria-label="Posetite našu WhatsApp aplikaciju"
          >
            <WhatsApp />
          </SocialIcon>
        </SocialContainer>
      </Left>

      <Center>
        <Title>{t("Footer.UsefulLinks")}</Title>
        <List>
          <ListItem>
            <Link
              to="/politika-privatnosti"
              style={{ fontFamily: "Righteous", color: "#f1f1f" }}
            >
              {t("Footer.PrivacyPolicy")}
            </Link>
          </ListItem>
          <br />
          <ListItem>
            <Link style={{ fontFamily: "Righteous", color: "#f1f1f" }}>
              {t("Footer.TermsOfUse")}
            </Link>
          </ListItem>
          <br />
          <ListItem>
            <Link style={{ fontFamily: "Righteous", color: "#f1f1f" }}>
              {t("Footer.CookieSettings")}
            </Link>
          </ListItem>
        </List>
      </Center>
      <Right>
        <Title>{t("Footer.Contact")}</Title>
        <RightWrapper>
          <ContactWrapper>
            <ContactItem>
              <Icon>
                <Room style={{ fontSize: "18px" }} />
              </Icon>
              <ContactHeader>{t("Footer.FindUs")}</ContactHeader>
            </ContactItem>
            <ContactText>{t("Footer.FindUsText")}</ContactText>
          </ContactWrapper>
          <ContactWrapper>
            <ContactItem>
              <Phone />
              <ContactHeader>{t("Footer.Contact")}</ContactHeader>
            </ContactItem>
            <ContactText>+381 63 129 7501</ContactText>
          </ContactWrapper>
          <ContactWrapper>
            <ContactItem>
              <MailOutline />
              <ContactHeader>{t("Footer.Email")}</ContactHeader>
            </ContactItem>
            <ContactText>snussrbija.com@gmail.com</ContactText>
          </ContactWrapper>
        </RightWrapper>
      </Right>
    </Container>
  );
};

export default Footer;
